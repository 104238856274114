import React, { useState, useEffect } from "react";
import axios from "axios";
import './RollOfferLetter.css';

const RollOfferLetter = () => {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
  const [offerDetails, setOfferDetails] = useState({
    name: "",
    position: "",
    salary: "",
    startDate: "",
    details: "",
    phone: "",
    employeeId: ""
  });
  const [loading, setLoading] = useState(false);
  const [offerLetters, setOfferLetters] = useState([]);

  // Fetch employees list
  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get("https://backend.nirvinfertilizerandpesticidesprivatelimited.com/api/employees");
        setEmployees(response.data);
      } catch (error) {
        console.error("Error fetching employees", error);
      }
    };
    fetchEmployees();
  }, []);

  // Fetch existing offer letters
  useEffect(() => {
    const fetchOfferLetters = async () => {
      try {
        const response = await axios.get("https://backend.nirvinfertilizerandpesticidesprivatelimited.com/api/offerletters");
        setOfferLetters(response.data);
      } catch (error) {
        console.error("Error fetching offer letters", error);
      }
    };
    fetchOfferLetters();
  }, []);

  // Handle employee selection from dropdown
  const handleEmployeeSelect = async (e) => {
    const id = e.target.value;
    setSelectedEmployeeId(id);

    if (id) {
      try {
        const response = await axios.get(`https://backend.nirvinfertilizerandpesticidesprivatelimited.com/api/employees/${id}`);
        const { name, position, phone } = response.data || {};

        setOfferDetails((prev) => ({
          ...prev,
          name,
          position,
          phone,
          employeeId: phone.slice(-4), // Generate employee ID
        }));
      } catch (error) {
        console.error("Error fetching employee details", error);
      }
    } else {
      resetOfferDetails();
    }
  };

  // Reset offer details
  const resetOfferDetails = () => {
    setOfferDetails({
      name: "",
      position: "",
      salary: "",
      startDate: "",
      details: "",
      phone: "",
      employeeId: ""
    });
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOfferDetails({ ...offerDetails, [name]: value });
  };

  // Handle form submission to save offer letter
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await axios.post("https://backend.nirvinfertilizerandpesticidesprivatelimited.com/api/offerletters", {
        ...offerDetails,
        employeeId: selectedEmployeeId,
        salary: Number(offerDetails.salary)
      });

      alert("Offer letter generated successfully!");

      // Reset form after submission
      setSelectedEmployeeId("");
      resetOfferDetails();
    } catch (error) {
      console.error("Error saving offer letter!", error.response?.data || error);
      alert("Failed to generate offer letter. Check console for details.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <h2 className="form-title">Generate Offer Letter</h2>
      <form onSubmit={handleSubmit} className="offer-letter-form">
        {/* Employee Selection Dropdown */}
        <div className="form-group">
          <label htmlFor="employeeSelect">Select Employee</label>
          <select id="employeeSelect" value={selectedEmployeeId} onChange={handleEmployeeSelect} required>
            <option value="">--Select Employee--</option>
            {employees.map((emp) => (
              <option key={emp._id} value={emp._id}>
                {emp.name}
              </option>
            ))}
          </select>
        </div>

        {/* Offer Letter Details */}
        <div className="form-group">
          <label>Name</label>
          <input type="text" value={offerDetails.name} readOnly />
        </div>
        <div className="form-group">
          <label>Position</label>
          <input type="text" value={offerDetails.position} readOnly />
        </div>
        <div className="form-group">
          <label>Mobile Number</label>
          <input type="text" value={offerDetails.phone} readOnly />
        </div>
        <div className="form-group">
          <label>Employee ID</label>
          <input type="text" value={offerDetails.employeeId} readOnly />
        </div>
        <div className="form-group">
          <label>Salary</label>
          <input
            type="number"
            name="salary"
            placeholder="Enter salary"
            value={offerDetails.salary}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Start Date</label>
          <input
            type="date"
            name="startDate"
            value={offerDetails.startDate}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Details</label>
          <textarea
            name="details"
            placeholder="Enter additional details"
            value={offerDetails.details}
            onChange={handleInputChange}
            required
          />
        </div>

        <button type="submit" disabled={loading}>
          {loading ? "Saving..." : "Generate Offer Letter"}
        </button>
      </form>
    </div>
  );
};

export default RollOfferLetter;
