import React, { useEffect, useState } from "react";
import axios from "axios";
import './ViewPayslips.css'; // Import a CSS file for styling
import logoo from './pages/logo.png';
import sign from './sign.png';

const ViewPayslips = () => {
  const [payslips, setPayslips] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [searchMonth, setSearchMonth] = useState(""); // State for month input
  const [searchYear, setSearchYear] = useState("");   // State for year input
  const [searchid, setSearchid] = useState("");   // State for id input

  // Fetch payslips on component mount
  useEffect(() => {
    const fetchPayslips = async () => {
      try {
        const response = await axios.get("https://backend.nirvinfertilizerandpesticidesprivatelimited.com/api/payslips");
        setPayslips(response.data);
      } catch (error) {
        setError("Error fetching payslips");
        console.error("Error fetching payslips:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPayslips();
  }, []);

  const handleDelete = async (id) => {
    const passKey = "KKFRAJ88";
    const userInput = window.prompt("Please enter the pass key to confirm deletion:");

    if (userInput !== passKey) {
      alert("Invalid pass key. Deletion aborted.");
      return;
    }

    if (window.confirm("Are you sure you want to delete this payslip?")) {
      try {
        await axios.delete(`https://backend.nirvinfertilizerandpesticidesprivatelimited.com/api/payslips/${id}`);
        setPayslips((prev) => prev.filter((payslip) => payslip._id !== id));
      } catch (error) {
        console.error("Error deleting payslip:", error);
      }
    }
  };

  const handlePrint = (payslip) => {
    const printWindow = window.open('', '', 'width=800,height=600');
    printWindow.document.write(`
      <html>
        <head>
          <title>Payslip</title>
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">
          <style>
            @media print {
              body { font-family: Arial, sans-serif; margin: 0; padding: 0; }
              .payslip { width: 100%; max-width: 700px; margin: auto; padding: 15px; border: 1px solid #ddd; box-shadow: 0px 0px 5px rgba(0,0,0,0.1); font-size: 16px; }
              .header { text-align: center; margin-bottom: 10px; border-bottom: 2px solid #333; padding-bottom: 10px; }
              .header img { width: 70px; margin-bottom: 5px; }
              .header h2 { font-size: 20px; margin: 6px 0; font-weight: bold; }
              .header p { font-size: 14px; color: #555; margin: 4px 0; }
              .section-title { font-weight: bold; background-color: #e8e8e8; padding: 6px; font-size: 15px; color: #333; margin-top: 12px; }
              .details, .salary-details, .attendance-details { width: 100%; font-size: 14px; }
              table { width: 100%; border-collapse: collapse; margin-top: 4px; }
              th, td { padding: 8px; border: 1px solid #ddd; }
              th { background-color: #f2f2f2; text-align: left; font-weight: bold; }
              .info-sections { display: flex; justify-content: space-between; margin-top: 10px; }
              .info-box { width: 48%; }
              .footer { display: flex; justify-content: space-between; margin-top: 20px; font-size: 14px; }
              .signature-box { text-align: center; }
              .signature-b p { margin-top: 60px; font-size: 14px; font-weight: bold; color: black; }
              .signature-box img { height: 50px; width: 80px; }
              .signature-box p { margin-top:10px; font-size: 14px; font-weight: bold; color: black; }
            }
          </style>
        </head>
        <body>
          <div class="payslip">
            <!-- Header -->
            <div class="header">
              <img src=${logoo} alt="Company Logo" />
              <h2>Klin Kaara Foundation</h2>
              <p>11, Vekanuru, Avanigadda, Krishna, Andhra Pradesh, 521121, India</p>
              <p>Phone: +91-6309193999 | Email: info@klinkaarafoundation.org</p>
              <p>CIN: U85500AP2024NPL114246</p>
              <h3>Payslip - ${new Date(payslip.dateOfIssuing).toLocaleString('default', { month: 'long' })} ${new Date(payslip.dateOfIssuing).getFullYear()}</h3>
            </div>
  
            <!-- Employee and Salary Information in Side-by-Side Boxes -->
            <div class="info-sections">
              <!-- Employee Information Box -->
              <div class="info-box">
                <div class="section-title">Employee Information</div>
                <table class="details">
                  <tr><td><strong>Employee ID</strong></td><td>KKF${payslip.phone ? payslip.phone.slice(-4) : "N/A"}</td></tr>
                  <tr><td><strong>Name</strong></td><td>${payslip.name}</td></tr>
                  <tr><td><strong>Mobile</strong></td><td>${payslip.phone}</td></tr>
                  <tr><td><strong>A/C No</strong></td><td>${payslip.accountNumber}</td></tr>
                  <tr><td><strong>Bank Name</strong></td><td>${payslip.bankName}</td></tr>
                  <tr><td><strong>PAN</strong></td><td>${payslip.pan}</td></tr>
                  <tr><td><strong>Aadhar</strong></td><td>${payslip.aadhar}</td></tr>
                  <tr><td><strong>PF Number</strong></td><td>${payslip.pfNumber}</td></tr>
                  <tr><td><strong>ESI Number</strong></td><td>${payslip.esiNumber}</td></tr>
                </table>
              </div>
  
              <!-- Salary Details Box -->
              <div class="info-box">
                <div class="section-title">Salary Details</div>
                <table class="salary-details">
                  <tr><td><strong>Basic Salary</strong></td><td>${payslip.basicSalary}</td></tr>
                  <tr><td><strong>HRA</strong></td><td>${payslip.hra}</td></tr>
                  <tr><td><strong>Transport Allowance</strong></td><td>${payslip.transportAllowance}</td></tr>
                  <tr><td><strong>Incentives</strong></td><td>${payslip.incentives || 0}</td></tr>
                  <tr><td><strong>Overtime Pay</strong></td><td>${payslip.overtimePay || 0}</td></tr>
                  <tr><td><strong>Other Allowances</strong></td><td>${payslip.otherAllowances || 0}</td></tr>
                  <tr><td><strong>Gross Salary</strong></td><td>${payslip.grossSalary || 0}</td></tr>
                  <tr><td><strong>PF Amount</strong></td><td>${payslip.pf || 0}</td></tr>
                  <tr><td><strong>Tax</strong></td><td>${payslip.tax || 0}</td></tr>
                  <tr><td><strong>Net Salary</strong></td><td><strong>${payslip.netSalary}</strong></td></tr>
                </table>
              </div>
            </div>
  
            <!-- Attendance Details Section -->
            <div class="section-title">Attendance Details</div>
            <table class="attendance-details">
              <tr><td><strong>Working Days</strong></td><td>${payslip.workingDays}</td></tr>
              <tr><td><strong>Days Present</strong></td><td>${payslip.presentDays}</td></tr>
              <tr><td><strong>Days Absent</strong></td><td>${payslip.workingDays - payslip.presentDays}</td></tr>
            </table>
  
            <!-- Footer Section with Signatures -->
            <div class="footer">
              <div class="signature-b">
                <p><strong>Employee Signature</strong></p>
              </div>
              <div class="signature-box">
                <img src=${sign} alt="Director Signature" />
                <p><strong>Director Signature</strong></p>
              </div>
            </div>
          </div>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
};

  

  // Print filtered table function
  const handlePrintTable = () => {
    const printWindow = window.open('', '', 'width=800,height=600');
    printWindow.document.write(`
      <html>
        <head>
          <title>Filtered Payslips</title>
          <style>
            body { font-family: Arial, sans-serif; margin: 0; }
            table { width: 100%; border-collapse: collapse; margin-top: 5px; font-size: 14px; }
            th, td { border: 1px solid #ddd; padding: 5px; text-align: left; }
            th { background-color: #f2f2f2; }
          </style>
        </head>
        <body>
          <h2>Filtered Payslips</h2>
          <table>
            <thead>
              <tr>
                <th>Employee Name</th>
                <th>Position</th>
                <th>Date of Issuing</th>
                <th>Mobile</th>
                <th>Basic Salary</th>
                <th>HRA</th>
                <th>Transport Allowance</th>
                <th>Incentives</th>
                <th>Overtime Pay</th>
                <th>Other Allowances</th>
                <th>Working Days</th>
                <th>Days Present</th>
                <th>Net Salary</th>
              </tr>
            </thead>
            <tbody>
              ${filteredPayslips.map(payslip => `
                <tr>
                  <td>${payslip.name}</td>
                  <td>${payslip.position}</td>
                  <td>${new Date(payslip.dateOfIssuing).toLocaleDateString()}</td>
                  <td>${payslip.phone}</td>
                  <td>${payslip.basicSalary}</td>
                  <td>${payslip.hra}</td>
                  <td>${payslip.transportAllowance}</td>
                  <td>${payslip.incentives || 0}</td>
                  <td>${payslip.overtimePay || 0}</td>
                  <td>${payslip.otherAllowances || 0}</td>
                  <td>${payslip.workingDays}</td>
                  <td>${payslip.presentDays}</td>
                  <td>${payslip.netSalary}</td>
                </tr>
              `).join('')}
            </tbody>
          </table>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  // Filter payslips based on the search month and year
  // Filter payslips based on the search month, year, and ID
  const filteredPayslips = payslips.filter(payslip => {
    const payslipDate = new Date(payslip.dateOfIssuing);

    // Check if the month and year match the search input
    const monthMatch = payslipDate.toLocaleString('default', { month: 'long' }).toLowerCase().includes(searchMonth.toLowerCase());
    const yearMatch = payslipDate.getFullYear().toString().includes(searchYear);

    // Check if the last 4 digits of the phone number match the search ID
    const idMatch = payslip.phone && payslip.phone.slice(-4).includes(searchid); // Ensure phone exists before slicing

    // Return true if all conditions match
    return monthMatch && yearMatch && idMatch;
  });


  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container">
      <h2 className="payslips-title">Payslips</h2>

      <div className="search-container">
        <input
          type="text"
          placeholder="Search by ID"
          value={searchid}
          onChange={(e) => setSearchid(e.target.value)}
        />
        <input
          type="text"
          placeholder="Search by month name"
          value={searchMonth}
          onChange={(e) => setSearchMonth(e.target.value)}
        />
        <input
          type="text"
          placeholder="Search by year"
          value={searchYear}
          onChange={(e) => setSearchYear(e.target.value)}
        />
        {/* Print Table Button */}
        <button onClick={handlePrintTable}>Print Table</button>
      </div>
      <table className="payslip-table">
        <thead>
          <tr>
            <th>Employee ID</th>
            <th>Employee Name</th>
            <th>Position</th>
            <th>Date of Issuing</th>
            <th>Mobile</th>
            <th>Basic Salary</th>
          
            <th>Working Days</th>
            <th>Days Present</th>
            <th>Net Salary</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredPayslips.map((payslip) => (
            <tr key={payslip._id}>
              <td>{payslip.phone ? payslip.phone.slice(-4) : "N/A"}</td>
              <td>{payslip.name}</td>
              <td>{payslip.position}</td>
              <td>{new Date(payslip.dateOfIssuing).toLocaleDateString()}</td>
              <td>{payslip.phone}</td>
              <td>{payslip.basicSalary}</td>
              
              <td>{payslip.workingDays}</td>
              <td>{payslip.presentDays}</td>
              <td>{payslip.netSalary}</td>
              <td>
                <button onClick={() => handlePrint(payslip)}>Print</button>
                <button onClick={() => handleDelete(payslip._id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ViewPayslips;
