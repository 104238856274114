import React, { useState } from "react";
import axios from "axios";
import './AddEmployee.module.css';

const NAddEmployee = () => {
  const [employee, setEmployee] = useState({
    nirvinName: "",
    nirvinPhone: "",
    nirvinEmail: "",
    nirvinAddress: "",
    nirvinPosition: "",
    nirvinDateOfJoining: "",
    nirvinPan: "",
    nirvinAadhar: "",
    nirvinSalary: "",
    image: null,
    nirvinPfNumber: "",
    nirvinEsiNumber: "",
    nirvinAccountNumber: "", // New field
    nirvinBankName: "" // New field
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEmployee({ ...employee, [name]: value });

    if (!value) {
      setErrors((prev) => ({ ...prev, [name]: `${name} is required` }));
    } else {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setEmployee({ ...employee, nirvinImage: file });
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    Object.keys(employee).forEach((key) => formData.append(key, employee[key]));

    try {
      await axios.post("https://backend.nirvinfertilizerandpesticidesprivatelimited.com/api/nirvin-employees", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      alert("Employee added successfully!");
      // Reset form after submission
      setEmployee({
        nirvinName: "",
        nirvinPhone: "",
        nirvinEmail: "",
        nirvinAddress: "",
        nirvinPosition: "",
        nirvinDateOfJoining: "",
        nirvinPan: "",
        nirvinAadhar: "",
        nirvinSalary: "",
        image: null,
        nirvinPfNumber: "",
        nirvinEsiNumber: "",
        nirvinAccountNumber: "", // New field
        nirvinBankName: "" // New field
      });
      setImagePreview(null);
      setErrors({});
    } catch (error) {
      console.error("There was an error uploading the data!", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <h2 className="form-title">Add Employee</h2>
      <form onSubmit={handleSubmit} encType="multipart/form-data" className="employee-form">
        <div className="form-group">
          <label htmlFor="nirvinName">Name</label>
          <input
            type="text"
            name="nirvinName"
            id="nirvinName"
            placeholder="Enter employee name"
            value={employee.nirvinName}
            onChange={handleInputChange}
            required
          />
          {errors.nirvinName && <span className="error">{errors.nirvinName}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="nirvinPhone">Phone Number</label>
          <input
            type="text"
            name="nirvinPhone"
            id="nirvinPhone"
            placeholder="Enter phone number"
            value={employee.nirvinPhone}
            onChange={handleInputChange}
            required
          />
          {errors.nirvinPhone && <span className="error">{errors.nirvinPhone}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="nirvinEmail">Email</label>
          <input
            type="email"
            name="nirvinEmail"
            id="nirvinEmail"
            placeholder="Enter email address"
            value={employee.nirvinEmail}
            onChange={handleInputChange}
            required
          />
          {errors.nirvinEmail && <span className="error">{errors.nirvinEmail}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="nirvinAddress">Address</label>
          <input
            type="text"
            name="nirvinAddress"
            id="nirvinAddress"
            placeholder="Enter address"
            value={employee.nirvinAddress}
            onChange={handleInputChange}
            required
          />
          {errors.nirvinAddress && <span className="error">{errors.nirvinAddress}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="nirvinPosition">Position</label>
          <input
            type="text"
            name="nirvinPosition"
            id="nirvinPosition"
            placeholder="Enter position"
            value={employee.nirvinPosition}
            onChange={handleInputChange}
            required
          />
          {errors.nirvinPosition && <span className="error">{errors.nirvinPosition}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="nirvinDateOfJoining">Date of Joining</label>
          <input
            type="date"
            name="nirvinDateOfJoining"
            id="nirvinDateOfJoining"
            value={employee.nirvinDateOfJoining}
            onChange={handleInputChange}
            required
          />
          {errors.nirvinDateOfJoining && <span className="error">{errors.nirvinDateOfJoining}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="nirvinAccountNumber">A/C Number</label>
          <input
            type="text"
            name="nirvinAccountNumber"
            id="nirvinAccountNumber"
            placeholder="Enter account number"
            value={employee.nirvinAccountNumber}
            onChange={handleInputChange}
            required
          />
          {errors.nirvinAccountNumber && <span className="error">{errors.nirvinAccountNumber}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="nirvinBankName">Bank Name</label>
          <input
            type="text"
            name="nirvinBankName"
            id="nirvinBankName"
            placeholder="Enter bank name"
            value={employee.nirvinBankName}
            onChange={handleInputChange}
            required
          />
          {errors.nirvinBankName && <span className="error">{errors.nirvinBankName}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="nirvinPan">PAN</label>
          <input
            type="text"
            name="nirvinPan"
            id="nirvinPan"
            placeholder="Enter PAN"
            value={employee.nirvinPan}
            onChange={handleInputChange}
            required
          />
          {errors.nirvinPan && <span className="error">{errors.nirvinPan}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="nirvinAadhar">Aadhar</label>
          <input
            type="text"
            name="nirvinAadhar"
            id="nirvinAadhar"
            placeholder="Enter Aadhar"
            value={employee.nirvinAadhar}
            onChange={handleInputChange}
            required
          />
          {errors.nirvinAadhar && <span className="error">{errors.nirvinAadhar}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="nirvinPfNumber">PF Number</label>
          <input
            type="text"
            name="nirvinPfNumber"
            id="nirvinPfNumber"
            placeholder="Enter PF Number"
            value={employee.nirvinPfNumber}
            onChange={handleInputChange}
            required
          />
          {errors.nirvinPfNumber && <span className="error">{errors.nirvinPfNumber}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="nirvinEsiNumber">ESI Number</label>
          <input
            type="text"
            name="nirvinEsiNumber"
            id="nirvinEsiNumber"
            placeholder="Enter ESI Number"
            value={employee.nirvinEsiNumber}
            onChange={handleInputChange}
            required
          />
          {errors.nirvinEsiNumber && <span className="error">{errors.nirvinEsiNumber}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="nirvinSalary">Salary</label>
          <input
            type="number"
            name="nirvinSalary"
            id="nirvinSalary"
            placeholder="Enter salary"
            value={employee.nirvinSalary}
            onChange={handleInputChange}
            required
          />
          {errors.nirvinSalary && <span className="error">{errors.nirvinSalary}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="nirvinImage">Profile Image</label>
          <input
            type="file"
            name="image"
            id="image"
            accept="image/*"
            onChange={handleImageChange}
          />
          {imagePreview && <img src={imagePreview} alt="Preview" className="image-preview" />}
        </div>

        <button type="submit" className="submit-button" disabled={loading}>
          {loading ? "Adding..." : "Add Employee"}
        </button>
      </form>
    </div>
  );
};

export default NAddEmployee;
