import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './RollOfferLetter.css';

const NRollOfferLetter = () => {
  const [formData, setFormData] = useState({
    nirvinEmployeeId: '',
    nirvinEmpid: '',
    nirvinName: '',
    nirvinPhone: '',
    nirvinEmail: '',
    nirvinPosition: '',
    nirvinSalary: '',
    nirvinStartDate: '',
    nirvinDetails: '',
    nirvinDateOfIssuing: '',
  });

  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get('https://backend.nirvinfertilizerandpesticidesprivatelimited.com/api/nirvin-employees');
        setEmployees(response.data);
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };

    fetchEmployees();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEmployeeSelect = (e) => {
    const selectedEmployeeId = e.target.value;
    const selectedEmployee = employees.find(emp => emp._id === selectedEmployeeId);

    if (selectedEmployee) {
      setFormData({
        ...formData,
        nirvinEmployeeId: selectedEmployeeId,
        nirvinEmpid: selectedEmployee.nirvinPhone.slice(-4), // Last 4 digits of phone
        nirvinName: selectedEmployee.nirvinName || '',
        nirvinPhone: selectedEmployee.nirvinPhone || '',
        nirvinEmail: selectedEmployee.nirvinEmail || '',
        nirvinPosition: selectedEmployee.nirvinPosition || '',
        nirvinSalary: selectedEmployee.nirvinSalary || '',
        // Set other fields you want to auto-fill
      });
    } else {
      setFormData({
        nirvinEmployeeId: '',
        nirvinEmpid: '',
        nirvinName: '',
        nirvinPhone: '',
        nirvinEmail: '',
        nirvinPosition: '',
        nirvinSalary: '',
        nirvinStartDate: '',
        nirvinDetails: '',
        nirvinDateOfIssuing: '',
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://backend.nirvinfertilizerandpesticidesprivatelimited.com/api/nirvin-offer-letters', formData);
      setFormData({
        nirvinEmployeeId: '',
        nirvinEmpid: '',
        nirvinName: '',
        nirvinPhone: '',
        nirvinEmail: '',
        nirvinPosition: '',
        nirvinSalary: '',
        nirvinStartDate: '',
        nirvinDetails: '',
        nirvinDateOfIssuing: '',
      });
      alert("Offer letter added successfully!");
    } catch (error) {
      console.error("Error adding offer letter:", error);
      alert("Failed to add offer letter. Please try again.");
    }
  };

  return (
    <div className="add-offer-letter-container">
      <h2>Add Offer Letter</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Employee</label>
          <select
            name="nirvinEmployeeId"
            value={formData.nirvinEmployeeId}
            onChange={handleEmployeeSelect}
            required
          >
            <option value="">Select Employee</option>
            {employees.map((employee) => (
              <option key={employee._id} value={employee._id}>
                {employee.nirvinName}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label>Emp ID</label>
          <input
            type="text"
            name="nirvinEmpid"
            value={formData.nirvinEmpid}
            readOnly // Make it read-only since it's calculated
          />
        </div>

        <div>
          <label>Name</label>
          <input
            type="text"
            name="nirvinName"
            value={formData.nirvinName}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label> Phone</label>
          <input
            type="text"
            name="nirvinPhone"
            value={formData.nirvinPhone}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label> Email</label>
          <input
            type="email"
            name="nirvinEmail"
            value={formData.nirvinEmail}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Position</label>
          <input
            type="text"
            name="nirvinPosition"
            value={formData.nirvinPosition}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Salary</label>
          <input
            type="number"
            name="nirvinSalary"
            value={formData.nirvinSalary}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label> Start Date</label>
          <input
            type="date"
            name="nirvinStartDate"
            value={formData.nirvinStartDate}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Details</label>
          <textarea
            name="nirvinDetails"
            value={formData.nirvinDetails}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Date of Issuing</label>
          <input
            type="date"
            name="nirvinDateOfIssuing"
            value={formData.nirvinDateOfIssuing}
            onChange={handleChange}
            required
          />
        </div>

        <button type="submit">Add Offer Letter</button>
      </form>
    </div>
  );
};

export default NRollOfferLetter;
