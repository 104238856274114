import React, { useState } from "react";
import axios from "axios";
import './AddBeneficiary.css'; // Import the CSS file

const AddBeneficiary = () => {
  const [beneficiary, setBeneficiary] = useState({
    name: "",
    phone: "",
    aadhar: "",
    address: "",
    problemDescription: "",
    amountDonated: "",
    image: null,
    dateOfDonation: new Date().toISOString().split("T")[0], // Set default to today
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);

  // Function to handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBeneficiary({ ...beneficiary, [name]: value });

    // Real-time validation
    if (!value) {
      setErrors((prev) => ({ ...prev, [name]: `${name} is required` }));
    } else {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  // Handle file input for image
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBeneficiary({ ...beneficiary, image: file });
      setImagePreview(URL.createObjectURL(file));
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    // Basic validation before submission
    if (Object.values(beneficiary).some(field => field === "") || !beneficiary.image) {
      alert("Please fill in all fields and upload an image.");
      setLoading(false);
      return;
    }

    const formData = new FormData();
    Object.keys(beneficiary).forEach((key) => formData.append(key, beneficiary[key]));

    try {
      await axios.post("https://backend.nirvinfertilizerandpesticidesprivatelimited.com/api/beneficiaries", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      alert("Beneficiary added successfully!");
      // Reset the form
      setBeneficiary({
        name: "",
        phone: "",
        aadhar: "",
        address: "",
        problemDescription: "",
        amountDonated: "",
        image: null,
        dateOfDonation: new Date().toISOString().split("T")[0], // Reset date
      });
      setImagePreview(null);
      setErrors({});
    } catch (error) {
      console.error("There was an error uploading the data!", error);
      alert("Error uploading beneficiary details. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <h2 className="form-title">Add Beneficiary</h2>
      <form onSubmit={handleSubmit} encType="multipart/form-data" className="beneficiary-form">
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="name"
            id="name"
            placeholder="Enter beneficiary name"
            value={beneficiary.name}
            onChange={handleInputChange}
            required
          />
          {errors.name && <span className="error">{errors.name}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="phone">Phone Number</label>
          <input
            type="text"
            name="phone"
            id="phone"
            placeholder="Enter phone number"
            value={beneficiary.phone}
            onChange={handleInputChange}
            required
          />
          {errors.phone && <span className="error">{errors.phone}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="aadhar">Aadhar</label>
          <input
            type="text"
            name="aadhar"
            id="aadhar"
            placeholder="Enter Aadhar"
            value={beneficiary.aadhar}
            onChange={handleInputChange}
            required
          />
          {errors.aadhar && <span className="error">{errors.aadhar}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="address">Address</label>
          <input
            type="text"
            name="address"
            id="address"
            placeholder="Enter address"
            value={beneficiary.address}
            onChange={handleInputChange}
            required
          />
          {errors.address && <span className="error">{errors.address}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="problemDescription">Problem Description</label>
          <textarea
            name="problemDescription"
            id="problemDescription"
            placeholder="Describe the problem"
            value={beneficiary.problemDescription}
            onChange={handleInputChange}
            required
          />
          {errors.problemDescription && <span className="error">{errors.problemDescription}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="amountDonated">Amount Donated</label>
          <input
            type="number"
            name="amountDonated"
            id="amountDonated"
            placeholder="Enter amount donated"
            value={beneficiary.amountDonated}
            onChange={handleInputChange}
            required
          />
          {errors.amountDonated && <span className="error">{errors.amountDonated}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="dateOfDonation">Date of Donation</label>
          <input
            type="date"
            name="dateOfDonation"
            id="dateOfDonation"
            value={beneficiary.dateOfDonation}
            onChange={handleInputChange}
            required
          />
          {errors.dateOfDonation && <span className="error">{errors.dateOfDonation}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="image">Image</label>
          <input
            type="file"
            name="image"
            id="image"
            onChange={handleImageChange}
            accept="image/*"
            required
          />
          {imagePreview && <img src={imagePreview} alt="Image Preview" className="image-preview" />}
        </div>

        <button type="submit" className="submit-button" disabled={loading}>
          {loading ? "Adding..." : "Add Beneficiary"}
        </button>
      </form>
    </div>
  );
};

export default AddBeneficiary;
