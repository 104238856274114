import React, { useState, useEffect } from "react";
import axios from "axios";
import './IDCardGenerator.css';
import logoo from './logo.png';
import directorSignature from './sign.png'; // Import the director's digital signature image

const IDCardGenerator = () => {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
  const [employeeDetails, setEmployeeDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  // Fetch employees list
  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get("https://backend.nirvinfertilizerandpesticidesprivatelimited.com/api/employees");
        setEmployees(response.data);
      } catch (error) {
        console.error("Error fetching employees", error);
      }
    };
    fetchEmployees();
  }, []);

  // Handle employee selection
  const handleEmployeeSelect = async (e) => {
    const id = e.target.value;
    setSelectedEmployeeId(id);

    if (id) {
      setLoading(true);
      try {
        const response = await axios.get(`https://backend.nirvinfertilizerandpesticidesprivatelimited.com/api/employees/${id}`);
        setEmployeeDetails(response.data);
      } catch (error) {
        console.error("Error fetching employee details", error);
      } finally {
        setLoading(false);
      }
    } else {
      setEmployeeDetails(null);
    }
  };

  // Function to print the ID card
  const printIDCard = () => {
    const printWindow = window.open('', '', 'width=800,height=600');
    printWindow.document.write(`
      <html>
        <head>
          <title>ID Card</title>
          <style>
            @media print {
              body { 
                font-family: 'Arial', sans-serif; 
                margin: 0; 
                padding: 0; 
                background-color: #f7f7f7; 
                color: #333; 
              }
              .id-card { 
                width: 2.5in; 
                height: 3.5in; 
                padding: 10px; 
                margin: 20px auto; 
               border: 3px solid #2d8604;
                background: rgb(192, 232, 180);
                border-radius: 5px; 
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: 12px; /* Increased base font size */
                position: relative;
              }
              .header { 
                text-align: center; 
                margin-bottom: 8px; 
              }
              .header img { 
                width: 40px; /* Larger company logo */
                margin-bottom: 3px; 
              }
              .header h2 { 
                font-size: 14px; /* Larger font for header */
                margin: 0; 
              }
              .header p { 
                font-size: 8px; /* Adjusted font for details */
                margin: 1px 0; 
                line-height: 1.1; 
              }
              .content {
                text-align: center; 
                margin: 5px 0; 
                width: 100%;
              }
              .content img {
                width: 80px; /* Larger box-shaped employee image */
                height: 80px;
                border-radius: 5px; /* Rounded corners for softer look */
                margin-bottom: 8px; /* Spacing below image */
              }
              .director-signature {
                width: 70px; /* Set width for signature */
    height: 50px; /* Reduced height for signature */
    margin-left: 150px;
    margin-top: 0px;
              }
    .director-signatur{
    font-size: 10px;
    margin-right: -110px;
    margin-top: 0px;
    margin-bottom: 10px;
  }
              .content p {
              margin-bottom:-4px;
                font-size: 10px; /* Larger font for content */
              }
               
          </style>
        </head>
        <body>
          <div class="id-card">
            <div class="header">
              <img src=${logoo} alt="Company Logo" />
              <h2>Klin Kaara Foundation</h2>
              <p>11, Vekanuru, Avanigadda</p>
              <p>AP, 521121, India</p>
              <p>Phone: +91-6309193999</p>
              <p>Email: info@klinkaarafoundation.org</p>
            </div>
            <div class="content">
              <img src="https://backend.nirvinfertilizerandpesticidesprivatelimited.com/uploads/${employeeDetails.image}" alt={employeeDetails.name} /> 
               <p><strong>Employee ID:</strong> KKF${employeeDetails.phone.slice(-4)}</p>
              <p><strong>Name:</strong> ${employeeDetails.name}</p>
              <p><strong>Position:</strong> ${employeeDetails.position}</p>
              <p><strong>Phone:</strong> ${employeeDetails.phone}</p>
              
            
             
            </div class='sig'>
            <img src="${directorSignature}" alt="Director Signature" class="director-signature" />
            <p class="director-signatur"><strong>Director Signature</strong></p>
          </div>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };


  return (
    <div className="container">
      <h2 className="form-title">Generate ID Card</h2>
      <div className="form-group">
        <label htmlFor="employeeSelect">Select Employee</label>
        <select id="employeeSelect" value={selectedEmployeeId} onChange={handleEmployeeSelect} required>
          <option value="">--Select Employee--</option>
          {employees.map((emp) => (
            <option key={emp._id} value={emp._id}>
              {emp.name}
            </option>
          ))}
        </select>
      </div>

      {loading && <p>Loading employee details...</p>}

      {employeeDetails && (
        <div className="id-card">
          <div className="header">
            <img src={logoo} alt="Company Logo" />
            <h2>Klin Kaara Foundation</h2>
            <p>11, Vekanuru, Avanigadda</p>
            <p>AP, 521121, India</p>
            <p>Phone: +91-6309193999</p>
            <p>Email: info@klinkaarafoundation.org</p>
          </div>
          <div className="content">
            {employeeDetails.image && <img src={`https://backend.nirvinfertilizerandpesticidesprivatelimited.com/uploads/${employeeDetails.image}`} alt={employeeDetails.name} />} 
            <p><strong>Employee ID:</strong> KKF{employeeDetails.phone.slice(-4)}</p>
            <p><strong>Name:</strong> {employeeDetails.name}</p>
            <p><strong>Position:</strong> {employeeDetails.position}</p>
            <p><strong>Phone:</strong> {employeeDetails.phone}</p>
           
            
          </div>
          <div className="director-signature" >
          <img src={directorSignature} alt="Director Signature" /> {/* Director's Signature */}
         
          </div>
          <p className="director-signatur"><strong>Director Signature</strong></p>
          <button onClick={printIDCard} className="print-button">Print ID Card</button>
        </div>
      )}
    </div>
  );
};

export default IDCardGenerator;
