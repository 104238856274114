import React, { useEffect, useState } from 'react';
import axios from 'axios';
import logoo from './logo.jpg';
import sign from './sign.png';

const NViewTerminationLetters = () => {
  const [terminationLetters, setTerminationLetters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchTerminationLetters = async () => {
      try {
        const response = await axios.get('https://backend.nirvinfertilizerandpesticidesprivatelimited.com/api/nirvin-terminationletters');
        setTerminationLetters(response.data);
      } catch (error) {
        console.error("Error fetching termination letters:", error);
        setError("Failed to fetch termination letters.");
      } finally {
        setLoading(false);
      }
    };

    fetchTerminationLetters();
  }, []);

  const handleDelete = async (id) => {
    const password = prompt("Please enter the password to confirm deletion:");
    
    // Replace 'yourPassword' with the actual password you want to use
    const yourPassword = "NFPRAJ99"; // Change this to your actual password
  
    if (password !== yourPassword) {
      alert("Incorrect password. Deletion canceled.");
      return;
    }
  
    if (window.confirm("Are you sure you want to delete this termination letter?")) {
      try {
        await axios.delete(`https://backend.nirvinfertilizerandpesticidesprivatelimited.com/api/nirvin-terminationletters/${id}`);
        setTerminationLetters(terminationLetters.filter(letter => letter._id !== id));
        alert("Termination letter deleted successfully!");
      } catch (error) {
        console.error("Error deleting termination letter:", error);
        alert("Failed to delete termination letter.");
      }
    }
  };
  

  const handlePrint = (terminationLetter) => {
    const terminationDate = new Date(terminationLetter.nirvinTerminationDate); // Corrected field reference
   
  
    const printWindow = window.open('', '', 'width=800,height=600');
    printWindow.document.write(`
      <html>
        <head>
          <title>Termination Letter</title>
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">
          <style>
            @media print {
              body { 
                font-family: 'Arial', sans-serif; 
                margin: 0; 
                padding: 20px; 
                background-color: #f7f7f7; 
                color: #333; 
              }
              .termination-letter { 
                max-width: 700px; 
                margin: auto; 
                padding: 30px; 
                background: white; 
                border: none; 
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
                border-radius: 5px; 
                font-size: 16px; 
              }
              .header { 
                text-align: center; 
                margin-bottom: 20px; 
              }
              .header img { 
                width: 80px; 
                margin-bottom: 10px; 
              }
              .header h2 { 
                font-size: 24px; 
                margin: 0; 
              }
              .header p { 
                font-size: 14px; 
                margin: 4px 0; 
              }
              .greeting { 
                margin: 20px 0; 
                font-size: 18px; 
                font-weight: bold; 
              }
              .content { 
                margin: 20px 0; 
                font-size: 16px; 
              }
              .reason, .additional-comments {
                margin-top: 20px;
                font-size: 16px;
                border: 1px solid #ccc;
                padding: 10px;
                border-radius: 4px;
                background-color: #f9f9f9; /* Light background for better contrast */
              }
              .footer { 
                display: flex; 
                justify-content: space-between; 
                margin-top: 30px; 
                font-size: 14px; 
                text-align: center; 
                align-items: center; 
              }
              .signature-box img { 
                height: 60px; 
                width: 100px; 
              }
              .signature-box p { 
                margin-top: 10px; 
                font-weight: bold; 
              }
                .signature-b { 
                margin-top: 50px; 
                font-weight: bold; 
              }
            }
          </style>
        </head>
        <body>
          <div class="termination-letter">
            <div class="header">
              <img src="${logoo}" alt="Company Logo" />
             <h2>Nirvin Fertilizer and Pesticides Private Limited</h2>
              <p>11-28, Vekanuru, Avanigadda, Krishna, Andhra Pradesh, 521121, India</p>
              <p>Phone: +91-6309193999 | Email: bgudivaka@gmail.com</p>
              <p>CIN: U10509AP2023PTC113421</p>
              <h3>Termination Letter</h3>
            </div>
  
            <div class="greeting">
              Dear ${terminationLetter.nirvinName},
            </div>
            
            <div class="content">
              <p>We regret to inform you that your employment as <strong>${terminationLetter.nirvinPosition}</strong> at Nirvin Fertilizer and Pesticides Private Limited will be terminated effective <strong>${terminationDate.toLocaleDateString()}</strong>.</p>
              
              <p>This decision was made following careful consideration of various factors, including:</p>
              <div class="reason">
                <strong>Reason for Termination:</strong> ${terminationLetter.nirvinReason || 'Not provided'}
              </div>
              
              <p>Please arrange to return any company property in your possession before the termination date. You are entitled to your remaining salary up to the termination date, as well as any accrued leave.</p>
              
              <p>We appreciate your contributions and wish you the best in your future endeavors.</p>
            </div>
  
            <div class="additional-comments">
              <strong>Additional Comments:</strong> ${terminationLetter.nirvinComments || 'None'}
            </div>
  
            <div class="footer">
              <div class="signature-b">
                <p><strong>Employee Signature</strong></p>
              </div>
              <div class="signature-box">
                <img src="${sign}" alt="Director Signature" />
                <p><strong>Director Signature</strong></p>
              </div>
            </div>
          </div>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };
  

  return (
    <div className="view-termination-letters-container">
      <h2>Termination Letters</h2>
      {loading && <p>Loading termination letters...</p>}
      {error && <p>{error}</p>}
      {terminationLetters.length === 0 && !loading && <p>No termination letters found.</p>}
      {terminationLetters.length > 0 && (
        <table className="termination-letter-table">
          <thead>
            <tr>
              <th>Nirvin Name</th>
              <th>Nirvin Phone</th>
              <th>Nirvin Position</th>
              <th>Reason</th>
              <th>Comments</th>
              <th>Date of Issuing</th>
              <th>Termination Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {terminationLetters.map((letter) => (
              <tr key={letter._id}>
                <td>{letter.nirvinName}</td>
                <td>{letter.nirvinPhone}</td>
                <td>{letter.nirvinPosition}</td>
                <td>{letter.nirvinReason}</td>
                <td>{letter.nirvinComments}</td>
                <td>{new Date(letter.nirvinDateOfIssuing).toLocaleDateString()}</td>
                <td>{new Date(letter.nirvinTerminationDate).toLocaleDateString()}</td>
                <td>
                  <button onClick={() => handleDelete(letter._id)}>Delete</button>
                  <button onClick={() => handlePrint(letter)}>Print</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default NViewTerminationLetters;
