import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import './BeneficiaryList.css'; // Import the CSS file

const BeneficiaryList = () => {
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortedBeneficiaries, setSortedBeneficiaries] = useState([]);
  const [sortDirection, setSortDirection] = useState("asc");
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  useEffect(() => {
    const fetchBeneficiaries = async () => {
      try {
        setLoading(true);
        const response = await axios.get("https://backend.nirvinfertilizerandpesticidesprivatelimited.com/api/beneficiaries");
        setBeneficiaries(response.data);
        setSortedBeneficiaries(response.data);
      } catch (error) {
        console.error("Error fetching beneficiaries:", error);
        setError("Failed to load beneficiaries.");
      } finally {
        setLoading(false);
      }
    };
    fetchBeneficiaries();
  }, []);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    
    const filteredBeneficiaries = beneficiaries.filter(beneficiary =>
      beneficiary.phone && beneficiary.phone.slice(-4).includes(value)
    );
    
    setSortedBeneficiaries(filteredBeneficiaries);
  };

  const handleSort = (column) => {
    const direction = sortDirection === "asc" ? "desc" : "asc";
    const sortedArray = [...sortedBeneficiaries].sort((a, b) => {
      const aValue = a[column] || '';
      const bValue = b[column] || '';
      
      if (column === "amountDonated") {
        return direction === "asc" ? a.amountDonated - b.amountDonated : b.amountDonated - a.amountDonated;
      } else {
        return direction === "asc" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      }
    });

    setSortedBeneficiaries(sortedArray);
    setSortDirection(direction);
  };

  // Handle delete beneficiary with passkey confirmation
  const handleDelete = async (id) => {
    const inputPasskey = prompt("Enter the passkey to delete this beneficiary:");
    if (inputPasskey !== "KKFRAJ88") {
      alert("Incorrect passkey. Deletion canceled.");
      return;
    }

    try {
      await axios.delete(`https://backend.nirvinfertilizerandpesticidesprivatelimited.com/api/beneficiaries/${id}`);
      const updatedBeneficiaries = beneficiaries.filter(beneficiary => beneficiary._id !== id);
      setBeneficiaries(updatedBeneficiaries);
      setSortedBeneficiaries(updatedBeneficiaries);
      alert("Beneficiary deleted successfully!");
    } catch (error) {
      console.error("Error deleting beneficiary:", error);
      alert("Failed to delete beneficiary. Please try again.");
    }
  };

  const handlePrint = () => {
    const printWindow = window.open('', '_blank', 'width=600,height=600');
    printWindow.document.write(`
      <html>
        <head>
          <title>Print Beneficiaries</title>
          <style>
            body { font-family: Arial, sans-serif; }
            .beneficiary-table { border-collapse: collapse; width: 100%; margin-top: 20px; }
            .beneficiary-table th, .beneficiary-table td { border: 1px solid #ddd; padding: 8px; }
            .beneficiary-table th { background-color: #007BFF; color: white; text-align: left; }
            .beneficiary-table tr:nth-child(even) { background-color: #f9f9f9; }
            .beneficiary-table tr:hover { background-color: #f1f1f1; }
          </style>
        </head>
        <body>
          <h2>Beneficiary List</h2>
          <table class="beneficiary-table">
            <thead>
              <tr>
                <th>Beneficiary ID</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Amount Donated</th>
                <th>Date of Donation</th>
              </tr>
            </thead>
            <tbody>
              ${sortedBeneficiaries.map(beneficiary => `
                <tr>
                  <td>${beneficiary.phone ? beneficiary.phone.slice(-4) : 'N/A'}</td>
                  <td>${beneficiary.name}</td>
                  <td>${beneficiary.phone}</td>
                  <td>₹${beneficiary.amountDonated}</td>
                  <td>${new Date(beneficiary.dateOfDonation).toLocaleDateString()}</td>
                </tr>
              `).join('')}
            </tbody>
          </table>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <div className="beneficiary-list-container">
      <h2 className="beneficiary-list-title">All Beneficiaries</h2>
      <input
        type="text"
        placeholder="Search by last 4 digits of phone..."
        value={searchTerm}
        onChange={handleSearch}
        className="search-input"
      />
      <button onClick={handlePrint} className="print-button">Print</button>
      {loading && <div className="loading">Loading beneficiaries...</div>}
      {error && <div className="error">{error}</div>}
      <table className="beneficiary-table">
        <thead>
          <tr>
            <th>Beneficiary ID</th>
            <th onClick={() => handleSort("name")} className="sortable-header">Name</th>
            <th>Phone</th>
            <th onClick={() => handleSort("amountDonated")} className="sortable-header">Amount Donated</th>
            <th onClick={() => handleSort("dateOfDonation")} className="sortable-header">Date of Donation</th>
            <th>Details</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {sortedBeneficiaries.length > 0 ? (
            sortedBeneficiaries.map((beneficiary) => (
              <tr key={beneficiary._id}>
                <td>{beneficiary.phone ? beneficiary.phone.slice(-4) : "N/A"}</td>
                <td>{beneficiary.name}</td>
                <td>{beneficiary.phone}</td>
                <td>₹{beneficiary.amountDonated}</td>
                <td>{new Date(beneficiary.dateOfDonation).toLocaleDateString()}</td>
                <td>
                  <Link to={`/dashboard/beneficiaries/${beneficiary._id}`} className="details-link">View Details</Link>
                </td>
                <td>
                  <button className="delete-button" onClick={() => handleDelete(beneficiary._id)}>Delete</button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7">No beneficiaries found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default BeneficiaryList;
