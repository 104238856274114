import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import './EmployeeList.css'; // Import the CSS file

const EmployeeList = () => {
  const [employees, setEmployees] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortedEmployees, setSortedEmployees] = useState([]);
  const [sortDirection, setSortDirection] = useState("asc");

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get("https://backend.nirvinfertilizerandpesticidesprivatelimited.com/api/employees");
        console.log("Fetched Employees:", response.data); // Debugging line
        setEmployees(response.data);
        setSortedEmployees(response.data);
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };
    fetchEmployees();
  }, []);

  // Handle search input by Employee ID (last 4 digits of phone number)
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);

    // Filter employees based on last 4 digits of phone number
    const filteredEmployees = employees.filter(employee =>
      employee.phone && employee.phone.slice(-4).includes(value) // Ensure phone exists before slicing
    );

    console.log("Filtered Employees:", filteredEmployees); // Debugging line
    setSortedEmployees(filteredEmployees);
  };

  // Handle sorting
 // Handle sorting
const handleSort = (column) => {
  const direction = sortDirection === "asc" ? "desc" : "asc";
  const sortedArray = [...sortedEmployees].sort((a, b) => {
    // Handling undefined values during sorting
    const aValue = a[column] || ''; // Fallback to empty string if undefined
    const bValue = b[column] || ''; // Fallback to empty string if undefined

    if (column === "salary") {
      return direction === "asc" ? a.salary - b.salary : b.salary - a.salary; // Explicit numeric sorting for salary
    } else {
      return direction === "asc" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    }
  });

  console.log("Sorted Employees:", sortedArray); // Debugging line
  setSortedEmployees(sortedArray);
  setSortDirection(direction);
};


  // Handle delete employee
 // Handle delete employee with passkey confirmation
const handleDelete = async (id) => {
  const inputPasskey = prompt("Enter the passkey to delete this employee:");
  if (inputPasskey !== "KKFRAJ88") { // Replace with your static passkey
    alert("Incorrect passkey. Deletion canceled.");
    return;
  }

  try {
    await axios.delete(`https://backend.nirvinfertilizerandpesticidesprivatelimited.com/api/employees/${id}`);
    const updatedEmployees = employees.filter(employee => employee._id !== id);
    setEmployees(updatedEmployees);
    setSortedEmployees(updatedEmployees);
    alert("Employee deleted successfully!");
  } catch (error) {
    console.error("Error deleting employee:", error);
    alert("Failed to delete employee. Please try again.");
  }
};


  // Handle print
  // Handle print
const handlePrint = () => {
  const printWindow = window.open('', '_blank', 'width=600,height=600');
  printWindow.document.write(`
    <html>
      <head>
        <title>Print Employees</title>
        <style>
          body { font-family: Arial, sans-serif; }
          .employee-table { border-collapse: collapse; width: 100%; }
          .employee-table th, .employee-table td { border: 1px solid #ddd; padding: 8px; }
          .employee-table th { background-color: #f2f2f2; text-align: left; }
          .employee-table tr:nth-child(even) { background-color: #f9f9f9; }
          .employee-table tr:hover { background-color: #ddd; }
        </style>
      </head>
      <body>
        <h2>Employee List</h2>
        <table class="employee-table">
          <thead>
            <tr>
              <th>Employee ID</th>
              <th>Name</th>
              <th>Position</th>
              <th>Email</th>
              <th>Salary</th>
            </tr>
          </thead>
          <tbody>
            ${sortedEmployees.map(employee => `
              <tr>
                <td>${employee.phone ? employee.phone.slice(-4) : 'N/A'}</td>
                <td>${employee.name}</td>
                <td>${employee.position}</td>
                <td>${employee.email}</td>
                <td>₹${employee.salary}</td>
              </tr>
            `).join('')}
          </tbody>
        </table>
      </body>
    </html>
  `);
  printWindow.document.close();
  printWindow.print();
};


  return (
    <div className="container">
      <h2 className="title">All Employees</h2>
      <input
        type="text"
        placeholder="Search by Employee ID (last 4 digits of phone)..."
        value={searchTerm}
        onChange={handleSearch}
        className="search-input"
      />
      <button onClick={handlePrint} className="print-button">Print</button> {/* Print Button */}
      <table className="employee-table">
        <thead>
          <tr>
          <th>Employee ID</th>
            <th onClick={() => handleSort("name")}>Name</th>
            <th onClick={() => handleSort("position")}>Position</th>
            <th onClick={() => handleSort("email")}>Email</th>
            <th onClick={() => handleSort("salary")}>Salary</th>
           
            <th>Details</th>
            <th>Actions</th> {/* Actions column for delete button */}
          </tr>
        </thead>
        <tbody>
          {sortedEmployees.length > 0 ? (
            sortedEmployees.map((employee) => (
              <tr key={employee._id}>
                 <td>{employee.phone ? employee.phone.slice(-4) : "N/A"}</td> {/* Safely checking phone and fallback */}
                <td>{employee.name}</td>
                <td>{employee.position}</td>
                <td>{employee.email}</td>
                <td>₹{employee.salary}</td>
               
                <td>
                  <Link to={`/dashboard/employees/${employee._id}`} className="details-link">View Details</Link>
                </td>
                <td>
                  <button className="delete-button" onClick={() => handleDelete(employee._id)}>Delete</button> {/* Delete Button */}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7">No employees found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default EmployeeList;
