import React, { useEffect, useState } from "react";
import axios from "axios";
import './ViewPayslips.css'; // Import a CSS file for styling
import logoo from './logo.jpg';
import sign from './sign.png';
import './RollOfferLetter.css';

const NViewPayslips = () => {
  const [payslips, setPayslips] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  // Fetch payslips on component mount
  useEffect(() => {
    const fetchPayslips = async () => {
      try {
        const response = await axios.get("https://backend.nirvinfertilizerandpesticidesprivatelimited.com/api/nirvin-payslips");
        setPayslips(response.data);
      } catch (error) {
        setError("Error fetching payslips");
        console.error("Error fetching payslips:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPayslips();
  }, []);

  const handleDelete = async (id) => {
    const passKey = "NFPRAJ99";
    const userInput = window.prompt("Please enter the pass key to confirm deletion:");

    if (userInput !== passKey) {
      alert("Invalid pass key. Deletion aborted.");
      return;
    }

    if (window.confirm("Are you sure you want to delete this payslip?")) {
      try {
        await axios.delete(`https://backend.nirvinfertilizerandpesticidesprivatelimited.com/api/nirvin-payslips/${id}`);
        setPayslips((prev) => prev.filter((payslip) => payslip._id !== id));
      } catch (error) {
        console.error("Error deleting payslip:", error);
      }
    }
  };

  const handlePrint = (payslip) => {
    const printWindow = window.open('', '', 'width=800,height=600');
    printWindow.document.write(`
      <html>
        <head>
          <title>Payslip</title>
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">
          <style>
            @media print {
              body { font-family: Arial, sans-serif; margin: 0; padding: 0; }
              .payslip { width: 100%; max-width: 700px; margin: auto; padding: 15px; border: 1px solid #ddd; box-shadow: 0px 0px 5px rgba(0,0,0,0.1); font-size: 16px; }
              .header { text-align: center; margin-bottom: 10px; border-bottom: 2px solid #333; padding-bottom: 10px; }
              .header img { width: 70px; margin-bottom: 5px; }
              .header h2 { font-size: 20px; margin: 6px 0; font-weight: bold; }
              .header p { font-size: 14px; color: #555; margin: 4px 0; }
              .section-title { font-weight: bold; background-color: #e8e8e8; padding: 6px; font-size: 15px; color: #333; margin-top: 12px; }
              .details, .salary-details, .attendance-details { width: 100%; font-size: 14px; }
              table { width: 100%; border-collapse: collapse; margin-top: 4px; }
              th, td { padding: 8px; border: 1px solid #ddd; }
              th { background-color: #f2f2f2; text-align: left; font-weight: bold; }
              .info-sections { display: flex; justify-content: space-between; margin-top: 10px; }
              .info-box { width: 48%; }
              .footer { display: flex; justify-content: space-between; margin-top: 20px; font-size: 14px; }
              .signature-box { text-align: center; }
              .signature-b p { margin-top: 60px; font-size: 14px; font-weight: bold; color: black; }
              .signature-box img { height: 50px; width: 80px; }
            }
          </style>
        </head>
        <body>
          <div class="payslip">
            <!-- Header -->
            <div class="header">
              <img src=${logoo} alt="Company Logo" />
              <h2>Nirvin Fertilizer and Pesticides Private Limited</h2>
              <p>11-28, Vekanuru, Avanigadda, Krishna, Andhra Pradesh, 521121, India</p>
              <p>Phone: +91-6309193999 | Email: bgudivaka@gmail.com</p>
              <p>CIN: U10509AP2023PTC113421</p>
              <h3>Payslip - ${new Date(payslip.nirvinDateOfIssuing).toLocaleString('default', { month: 'long' })} ${new Date(payslip.nirvinDateOfIssuing).getFullYear()}</h3>
            </div>
  
            <!-- Employee and Salary Information in Side-by-Side Boxes -->
            <div class="info-sections">
              <!-- Employee Information Box -->
              <div class="info-box">
                <div class="section-title">Employee Information</div>
                <table class="details">
                  <tr><td><strong>Employee ID</strong></td><td>NFP${payslip.nirvinPhone ? payslip.nirvinPhone.slice(-4) : "N/A"}</td></tr>
                  <tr><td><strong>Name</strong></td><td>${payslip.nirvinName}</td></tr>
                  <tr><td><strong>Mobile</strong></td><td>${payslip.nirvinPhone}</td></tr>
                  <tr><td><strong>A/C No</strong></td><td>${payslip.nirvinAccountNumber}</td></tr>
                  <tr><td><strong>Bank Name</strong></td><td>${payslip.nirvinBankName}</td></tr>
                  <tr><td><strong>PAN</strong></td><td>${payslip.nirvinPan}</td></tr>
                  <tr><td><strong>Aadhar</strong></td><td>${payslip.nirvinAadhar}</td></tr>
                  <tr><td><strong>PF Number</strong></td><td>${payslip.nirvinPf}</td></tr>
                  <tr><td><strong>ESI Number</strong></td><td>${payslip.nirvinEsiNumber}</td></tr>
                </table>
              </div>
  
              <!-- Salary Details Box -->
              <div class="info-box">
                <div class="section-title">Salary Details</div>
                <table class="salary-details">
                  <tr><td><strong>Basic Salary</strong></td><td>${payslip.nirvinBasicSalary}</td></tr>
                  <tr><td><strong>HRA</strong></td><td>${payslip.nirvinHra}</td></tr>
                  <tr><td><strong>Transport Allowance</strong></td><td>${payslip.nirvinTransportAllowance}</td></tr>
                  <tr><td><strong>Incentives</strong></td><td>${payslip.nirvinIncentives || 0}</td></tr>
                  <tr><td><strong>Overtime Pay</strong></td><td>${payslip.nirvinOvertimePay || 0}</td></tr>
                  <tr><td><strong>Other Allowances</strong></td><td>${payslip.nirvinOtherAllowances || 0}</td></tr>
                  <tr><td><strong>Gross Salary</strong></td><td>${payslip.nirvinGrossSalary || 0}</td></tr>
                  <tr><td><strong>PF Amount</strong></td><td>${payslip.nirvinPfAmount || 0}</td></tr>
                  <tr><td><strong>Tax</strong></td><td>${payslip.nirvinTax || 0}</td></tr>
                  <tr><td><strong>Net Salary</strong></td><td><strong>${payslip.nirvinNetSalary}</strong></td></tr>
                </table>
              </div>
            </div>
  
            <!-- Attendance Details Section -->
            <div class="section-title">Attendance Details</div>
            <table class="attendance-details">
              <tr><td><strong>Working Days</strong></td><td>${payslip.nirvinWorkingDays}</td></tr>
              <tr><td><strong>Days Present</strong></td><td>${payslip.nirvinPresentDays}</td></tr>
              <tr><td><strong>Days Absent</strong></td><td>${payslip.nirvinWorkingDays - payslip.nirvinPresentDays}</td></tr>
            </table>
  
            <!-- Signature Section -->
            <div class="signature-box">
              <img src=${sign} alt="Signature" />
              <p>Authorized Signatory</p>
            </div>
          </div>
        </body>
      </html>
    `);

    printWindow.document.close();

    setTimeout(() => {
      printWindow.print();
      printWindow.close();
    }, 1000);
};


  if (loading) return <div>Loading payslips...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="payslip-container">
      <h1>Nirvin Pvt Ltd Payslips</h1>
      {payslips.length === 0 ? (
        <p>No payslips available</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Employee ID</th>
              <th>Name</th>
              <th>Date of Issuing</th>
              <th>Net Salary</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {payslips.map((payslip) => (
              <tr key={payslip._id}>
                <td>{payslip.nirvinPhone.slice(-4)}</td>
                <td>{payslip.nirvinName}</td>
                <td>{new Date(payslip.nirvinDateOfIssuing).toLocaleDateString()}</td>
                <td>{payslip.nirvinNetSalary}</td>
                <td>
                  <button onClick={() => handlePrint(payslip)}>Print</button>
                  <button onClick={() => handleDelete(payslip._id)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default NViewPayslips;
