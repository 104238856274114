import React, { useState, useEffect } from "react";
import axios from "axios";
import './AddPayslip.css';

const AddPayslip = () => {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
  const [employee, setEmployee] = useState({
    name: "",
    phone: "",
    email: "",
    address: "",
    position: "",
    dateOfJoining: "",
    accountNumber:'',
    bankName :'',
    pan: "",
    aadhar: "",
    pfNumber: "",
    esiNumber: "",
    salary: 0,
  });
  const [payslip, setPayslip] = useState({
    basicSalary: "",
    hra: "",
    transportAllowance: "",
    pfNumber: "",
    pf: "",
    tax: "",
    netSalary: 0,
    workingDays: "",
    presentDays: "",
    incentives: "",
    overtimePay: "",
    otherAllowances: "",
    grossSalary: 0,
    dateOfIssuing: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get("https://backend.nirvinfertilizerandpesticidesprivatelimited.com/api/employees");
        setEmployees(response.data);
      } catch (error) {
        console.error("Error fetching employees", error);
      }
    };

    fetchEmployees();
  }, []);

  const handleEmployeeSelect = async (e) => {
    const id = e.target.value;
    setSelectedEmployeeId(id);

    if (id) {
      try {
        const response = await axios.get(`https://backend.nirvinfertilizerandpesticidesprivatelimited.com/api/employees/${id}`);
        const empData = response.data;

        setEmployee({
          name: empData.name,
          phone: empData.phone,
          email: empData.email,
          address: empData.address,
          position: empData.position,
          dateOfJoining: empData.dateOfJoining,
          pan: empData.pan,
          aadhar: empData.aadhar,
          accountNumber:empData.accountNumber,
          bankName:empData.bankName,
          pfNumber: empData.pfNumber,
          esiNumber: empData.esiNumber,
          salary: empData.salary,
        });
      } catch (error) {
        console.error("Error fetching employee details", error);
      }
    } else {
      setEmployee({
        name: "",
        phone: "",
        email: "",
        address: "",
        position: "",
        dateOfJoining: "",
        accountNumber:'',
    bankName :'',
        pan: "",
        aadhar: "",
        pfNumber: "",
        esiNumber: "",
        salary: 0,
      });
    }
  };

  const handlePayslipInputChange = (e) => {
    const { name, value } = e.target;
    setPayslip({ ...payslip, [name]: value });

    if (!value) {
      setErrors((prev) => ({ ...prev, [name]: `${name} is required` }));
    } else {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }

    calculateGrossAndNetSalary({
      ...payslip,
      [name]: value,
    });
  };

  const calculateGrossAndNetSalary = (data) => {
    const gross = parseFloat(data.basicSalary || 0) +
                  parseFloat(data.hra || 0) +
                  parseFloat(data.transportAllowance || 0) +
                  parseFloat(data.incentives || 0) +
                  parseFloat(data.overtimePay || 0) +
                  parseFloat(data.otherAllowances || 0);
    const deductions = parseFloat(data.pf || 0) + parseFloat(data.tax || 0);

    setPayslip((prev) => ({ 
      ...prev, 
      grossSalary: gross,
      netSalary: gross - deductions 
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const payslipData = {
      ...payslip,
      employeeId: selectedEmployeeId,
      name: employee.name,
      dateOfJoining: employee.dateOfJoining,
      position: employee.position,
      phone: employee.phone,
      email: employee.email,
      accountNumber:employee.accountNumber,
      bankName:employee.bankName,
      pan: employee.pan,
      aadhar: employee.aadhar,
      pfNumber: employee.pfNumber,
      esiNumber: employee.esiNumber,
      salary: employee.salary,
    };

    try {
      await axios.post("https://backend.nirvinfertilizerandpesticidesprivatelimited.com/api/payslips", payslipData);
      alert("Payslip added successfully!");

      setSelectedEmployeeId("");
      setEmployee({
        name: "",
        phone: "",
        email: "",
        address: "",
        position: "",
        dateOfJoining: "",
        accountNumber:'',
        bankName :'',
        pan: "",
        aadhar: "",
        pfNumber: "",
        esiNumber: "",
        salary: 0,
      });
      setPayslip({
        basicSalary: "",
        hra: "",
        transportAllowance: "",
        pfNumber: "",
        pf: "",
        tax: "",
        netSalary: 0,
        workingDays: "",
        presentDays: "",
        incentives: "",
        overtimePay: "",
        otherAllowances: "",
        grossSalary: 0,
        dateOfIssuing: "",
      });
      setErrors({});
    } catch (error) {
      console.error("There was an error saving the payslip data!", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <h2 className="form-title">Add Payslip</h2>
      <form onSubmit={handleSubmit} className="payslip-form">
        
        {/* Employee Selection Dropdown */}
        <div className="form-group">
          <label htmlFor="employeeSelect">Select Employee</label>
          <select
            id="employeeSelect"
            value={selectedEmployeeId}
            onChange={handleEmployeeSelect}
            required
          >
            <option value="">--Select Employee--</option>
            {employees.map((emp) => (
              <option key={emp._id} value={emp._id}>
                {emp.name}
              </option>
            ))}
          </select>
        </div>

        {/* Employee Details */}
        <div className="form-group"><label>Emloyee ID</label><input type="text" value={employee.phone ? employee.phone.slice(-4) : "N/A"} readOnly /></div>
        <div className="form-group"><label>Name</label><input type="text" value={employee.name} readOnly /></div>
        <div className="form-group"><label>Phone</label><input type="text" value={employee.phone} readOnly /></div>
        <div className="form-group"><label>Email</label><input type="email" value={employee.email} readOnly /></div>
        <div className="form-group"><label>Address</label><input type="text" value={employee.address} readOnly /></div>
        <div className="form-group"><label>Position</label><input type="text" value={employee.position} readOnly /></div>
        <div className="form-group"><label>Date of Joining</label><input type="text" value={employee.dateOfJoining} readOnly /></div>
        <div className="form-group"><label>A/C No</label><input type="text" value={employee.accountNumber} readOnly /></div>
        <div className="form-group"><label>Bank Name</label><input type="text" value={employee.bankName} readOnly /></div>
        <div className="form-group"><label>PAN</label><input type="text" value={employee.pan} readOnly /></div>
        <div className="form-group"><label>Aadhar</label><input type="text" value={employee.aadhar} readOnly /></div>
        <div className="form-group"><label>PF Number</label><input type="text" value={employee.pfNumber} readOnly /></div>
        <div className="form-group"><label>ESI Number</label><input type="text" value={employee.esiNumber} readOnly /></div>
        <div className="form-group"><label>Salary</label><input type="number" value={employee.salary} readOnly /></div>

        {/* Payslip Inputs */}
        <div className="form-group"><label>Basic Salary</label><input type="number" name="basicSalary" placeholder="Enter basic salary" value={payslip.basicSalary} onChange={handlePayslipInputChange} required /></div>
        <div className="form-group"><label>HRA</label><input type="number" name="hra" placeholder="Enter HRA" value={payslip.hra} onChange={handlePayslipInputChange} /></div>
        <div className="form-group"><label>Transport Allowance</label><input type="number" name="transportAllowance" placeholder="Enter transport allowance" value={payslip.transportAllowance} onChange={handlePayslipInputChange} /></div>
        <div className="form-group"><label>Working Days</label><input type="number" name="workingDays" placeholder="Enter working days" value={payslip.workingDays} onChange={handlePayslipInputChange} /></div>
        <div className="form-group"><label>Present Days</label><input type="number" name="presentDays" placeholder="Enter present days" value={payslip.presentDays} onChange={handlePayslipInputChange} /></div>
        <div className="form-group"><label>Incentives</label><input type="number" name="incentives" placeholder="Enter incentives" value={payslip.incentives} onChange={handlePayslipInputChange} /></div>
        <div className="form-group"><label>Overtime Pay</label><input type="number" name="overtimePay" placeholder="Enter overtime pay" value={payslip.overtimePay} onChange={handlePayslipInputChange} /></div>
        <div className="form-group"><label>Other Allowances</label><input type="number" name="otherAllowances" placeholder="Enter other allowances" value={payslip.otherAllowances} onChange={handlePayslipInputChange} /></div>
        <div className="form-group"><label>PF</label><input type="number" name="pf" placeholder="Enter PF amount" value={payslip.pf} onChange={handlePayslipInputChange} /></div>
        <div className="form-group"><label>Tax</label><input type="number" name="tax" placeholder="Enter tax amount" value={payslip.tax} onChange={handlePayslipInputChange} /></div>
        <div className="form-group"><label>Date of Issuing</label><input type="date" name="dateOfIssuing" value={payslip.dateOfIssuing} onChange={handlePayslipInputChange} required /></div>
        
        {/* Display Gross Salary and Net Salary */}
        <div className="form-group"><label>Gross Salary</label><input type="number" value={payslip.grossSalary} readOnly /></div>
        <div className="form-group"><label>Net Salary</label><input type="number" value={payslip.netSalary} readOnly /></div>

        <button type="submit" disabled={loading}>
          {loading ? "Saving..." : "Add Payslip"}
        </button>
      </form>
    </div>
  );
};

export default AddPayslip;
