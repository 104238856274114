// IDCardGenerator.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './IDCardGenerator.css'; // Ensure you create this CSS file for custom styles
import './RollOfferLetter.css';
import logoo from './logo.jpg'; // Path to your logo image
import directorSignature from './sign.png'; // Path to director's signature image

const NIDCardGenerator = () => {
  const [employees, setEmployees] = useState([]);
  const [formData, setFormData] = useState({
    nirvinEmployeeId: '',
    nirvinEmpid: '',
    nirvinName: '',
    nirvinPhone: '',
    nirvinEmail: '',
    nirvinPosition: '',
    nirvinSalary: '',
    nirvinStartDate: '',
    nirvinDetails: '',
    nirvinDateOfIssuing: '',
    nirvinImage: '',
  });

  // Fetch employees from API
  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get('https://backend.nirvinfertilizerandpesticidesprivatelimited.com/api/nirvin-employees');
        setEmployees(response.data);
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };
    fetchEmployees();
  }, []);

  // Handle employee selection
  const handleEmployeeSelect = (e) => {
    const selectedEmployeeId = e.target.value;
    const selectedEmployee = employees.find(emp => emp._id === selectedEmployeeId);

    if (selectedEmployee) {
      setFormData({
        nirvinEmployeeId: selectedEmployeeId,
        nirvinEmpid: selectedEmployee.nirvinPhone.slice(-4), // Last 4 digits of phone as Emp ID suffix
        nirvinName: selectedEmployee.nirvinName || '',
        nirvinPhone: selectedEmployee.nirvinPhone || '',
        nirvinEmail: selectedEmployee.nirvinEmail || '',
        nirvinPosition: selectedEmployee.nirvinPosition || '',
        nirvinSalary: selectedEmployee.nirvinSalary || '',
        nirvinStartDate: selectedEmployee.nirvinStartDate || '',
        nirvinDetails: selectedEmployee.nirvinDetails || '',
        nirvinDateOfIssuing: new Date().toLocaleDateString(), // Auto-fill with current date
        nirvinImage: selectedEmployee.nirvinImage || '',
      });
    } else {
      setFormData({
        nirvinEmployeeId: '',
        nirvinEmpid: '',
        nirvinName: '',
        nirvinPhone: '',
        nirvinEmail: '',
        nirvinPosition: '',
        nirvinSalary: '',
        nirvinStartDate: '',
        nirvinDetails: '',
        nirvinDateOfIssuing: '',
        nirvinImage: '',
      });
    }
  };

  // Print function
  const printIDCard = () => {
    const printWindow = window.open('', '', 'width=800,height=600');
    printWindow.document.write(`
      <html>
        <head>
          <title>ID Card</title>
          <style>
            @media print {
              body { 
                font-family: 'Arial', sans-serif; 
                margin: 0; 
                padding: 0; 
                background-color: #f7f7f7; 
                color: #333; 
              }
              .id-card { 
                width: 2.5in; 
                height: 3.5in; 
                padding: 10px; 
                margin: 20px auto; 
                border: 3px solid #2d8604;
                background: rgb(192, 232, 180);
                border-radius: 5px; 
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: 12px;
                position: relative;
              }
              .header { 
                text-align: center; 
                margin-bottom: 8px; 
              }
              .header img { 
                width: 40px; 
                margin-bottom: 3px; 
              }
              .header h2 { 
                font-size: 14px; 
                margin: 0; 
              }
              .header p { 
                font-size: 8px; 
                margin: 1px 0; 
                line-height: 1.1; 
              }
              .content {
                text-align: center; 
                margin: 5px 0; 
                width: 100%;
              }
              .content img {
                width: 80px; 
                height: 80px;
                border-radius: 5px;
                margin-bottom: 8px;
              }
              .director-signature {
                width: 70px;
                height: 50px;
                position: absolute;
                bottom: 20px;
                right: 10px;
              }
              .director-signatur {
                font-size: 10px;
                position: absolute;
                bottom: 10px;
                right: 20px;
              }
              .content p {
                font-size: 10px;
                margin-bottom: -4px;
              }
            }
          </style>
        </head>
        <body>
          <div class="id-card">
            <div class="header">
              <img src="${logoo}" alt="Company Logo" />
              <h2>Nirvin Fertilizer and Pesticides Pvt. Ltd</h2>
              <p>11, Vekanuru, Avanigadda</p>
              <p>11-28, Vekanuru, Avanigadda, Krishna, Andhra Pradesh, 521121</p>
               <p><strong>Email:</strong> bgudivaka@gmail.com <strong>Phone:</strong> +91 6309193999</p>
             
            </div>
            <div class="content">
              <img src="https://backend.nirvinfertilizerandpesticidesprivatelimited.com/${formData.nirvinImage.replace(/\\/g, '/')}" alt="${formData.nirvinName}" /> 
              <p><strong>Employee ID:</strong> NFP${formData.nirvinEmpid}</p>
              <p><strong>Name:</strong> ${formData.nirvinName}</p>
              <p><strong>Position:</strong> ${formData.nirvinPosition}</p>
              <p><strong>Phone:</strong> ${formData.nirvinPhone}</p>
            </div>
            <img src="${directorSignature}" alt="Director Signature" class="director-signature" />
            <p class="director-signatur"><strong>Director Signature</strong></p>
          </div>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    printIDCard();
  };

  return (
    <div className="add-id-card-container">
      <h2>Generate ID Card</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label> Employee</label>
          <select
            name="nirvinEmployeeId"
            value={formData.nirvinEmployeeId}
            onChange={handleEmployeeSelect}
            required
          >
            <option value="">Select Employee</option>
            {employees.map((employee) => (
              <option key={employee._id} value={employee._id}>
                {employee.nirvinName}
              </option>
            ))}
          </select>
        </div>
        <button type="submit">Generate ID Card</button>
      </form>
    </div>
  );
};

export default NIDCardGenerator;
