import React, { useEffect, useState } from "react";
import axios from "axios";
import './ViewOfferLetters.css'; // Separate CSS for termination letter styles
import logoo from './pages/logo.png'; // Company logo
import sign from './sign.png'; // Director's signature image

const ViewTerminationLetters = () => {
  const [terminationLetters, setTerminationLetters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [searchMonth, setSearchMonth] = useState("");
  const [searchYear, setSearchYear] = useState("");
  const [searchId, setSearchId] = useState("");

  // Fetch termination letters on component mount
  useEffect(() => {
    const fetchTerminationLetters = async () => {
      try {
        const response = await axios.get("https://backend.nirvinfertilizerandpesticidesprivatelimited.com/api/terminationletters");
        setTerminationLetters(response.data);
      } catch (error) {
        setError("Error fetching termination letters");
        console.error("Error fetching termination letters:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTerminationLetters();
  }, []);

  const handleDelete = async (id) => {
    const passKey = "KKFRAJ88"; // This should ideally be handled more securely.
    const userInput = window.prompt("Please enter the pass key to confirm deletion:");
  
    if (userInput !== passKey) {
      alert("Invalid pass key. Deletion aborted.");
      return;
    }
  
    if (window.confirm("Are you sure you want to delete this termination letter?")) {
      try {
        const response = await axios.delete(`https://backend.nirvinfertilizerandpesticidesprivatelimited.com/api/terminationletters/${id}`);
        alert(response.data.message);
        setTerminationLetters((prev) => prev.filter((letter) => letter._id !== id));
      } catch (error) {
        console.error("Error deleting termination letter:", error);
        alert("Failed to delete the termination letter.");
      }
    }
  };
  

  const handlePrint = (terminationLetter) => {
    const terminationDate = new Date(terminationLetter.terminationDate);
  
    const printWindow = window.open('', '', 'width=800,height=600');
    printWindow.document.write(`
      <html>
        <head>
          <title>Termination Letter</title>
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">
          <style>
            @media print {
              body { 
                font-family: 'Arial', sans-serif; 
                margin: 0; 
                padding: 20px; 
                background-color: #f7f7f7; 
                color: #333; 
              }
              .termination-letter { 
                max-width: 700px; 
                margin: auto; 
                padding: 30px; 
                background: white; 
                border: none; 
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
                border-radius: 5px; 
                font-size: 16px; 
              }
              .header { 
                text-align: center; 
                margin-bottom: 20px; 
              }
              .header img { 
                width: 80px; 
                margin-bottom: 10px; 
              }
              .header h2 { 
                font-size: 24px; 
                margin: 0; 
              }
              .header p { 
                font-size: 14px; 
                margin: 4px 0; 
              }
              .greeting { 
                margin: 20px 0; 
                font-size: 18px; 
                font-weight: bold; 
              }
              .content { 
                margin: 20px 0; 
                font-size: 16px; 
              }
              .reason, .additional-comments {
                margin-top: 20px;
                font-size: 16px;
                border: 1px solid #ccc;
                padding: 10px;
                border-radius: 4px;
              }
              .footer { 
                display: grid; 
                grid-template-columns: 1fr 1fr; 
                gap: 20px; 
                margin-top: 30px; 
                font-size: 14px; 
                text-align: center; 
                align-items: center; 
              }
              .signature-box img { 
                height: 60px; 
                width: 100px; 
              }
              .signature-box p { 
                margin-top: 10px; 
                font-weight: bold; 
              }
              .signature-b p { 
                margin-top: 66px; 
                font-weight: bold; 
              }
            }
          </style>
        </head>
        <body>
          <div class="termination-letter">
            <div class="header">
              <img src=${logoo} alt="Company Logo" />
              <h2>Klin Kaara Foundation</h2>
              <p>11, Vekanuru, Avanigadda, Krishna, Andhra Pradesh, 521121, India</p>
              <p>Phone: +91-6309193999 | Email: info@klinkaarafoundation.org</p>
              <p>CIN: U85500AP2024NPL114246</p>
              <h3>Termination Letter</h3>
            </div>
  
            <div class="greeting">
              Dear ${terminationLetter.name},
            </div>
            
            <div class="content">
              <p>We regret to inform you that your employment as <strong>${terminationLetter.position}</strong> at Klin Kaara Foundation will be terminated effective <strong>${terminationDate.toLocaleDateString()}</strong>.</p>
              
              <p>This decision was made following careful consideration of various factors, including:</p>
              <div class="reason">
                <strong>Reason for Termination:</strong> ${terminationLetter.reason || 'Not provided'}
              </div>
              
              <p>Please arrange to return any company property in your possession before the termination date. You are entitled to your remaining salary up to the termination date, as well as any accrued leave.</p>
              
              <p>We appreciate your contributions and wish you the best in your future endeavors.</p>
            </div>
  
            <div class="additional-comments">
              <strong>Additional Comments:</strong> ${terminationLetter.comments || 'None'}
            </div>
  
            <div class="footer">
              <div class="signature-b">
                <p><strong>Employee Signature</strong></p>
              </div>
              <div class="signature-box">
                <img src=${sign} alt="Director Signature" />
                <p><strong>Director Signature</strong></p>
              </div>
            </div>
          </div>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };
  
  
  // Filter termination letters based on the search month, year, and ID
  const filteredTerminationLetters = terminationLetters.filter(letter => {
    const letterDate = new Date(letter.dateOfIssuing);

    const monthMatch = letterDate.toLocaleString('default', { month: 'long' }).toLowerCase().includes(searchMonth.toLowerCase());
    const yearMatch = letterDate.getFullYear().toString().includes(searchYear);
    const idMatch = letter.employeeId && letter.employeeId.includes(searchId);

    return monthMatch && yearMatch && idMatch;
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container">
      <h2 className="termination-letters-title">Termination Letters</h2>

      <div className="search-container">
        <input
          type="text"
          placeholder="Search by Employee ID"
          value={searchId}
          onChange={(e) => setSearchId(e.target.value)}
        />
        <input
          type="text"
          placeholder="Search by month name"
          value={searchMonth}
          onChange={(e) => setSearchMonth(e.target.value)}
        />
        <input
          type="text"
          placeholder="Search by year"
          value={searchYear}
          onChange={(e) => setSearchYear(e.target.value)}
        />
      </div>
      <table className="termination-letter-table">
        <thead>
          <tr>
            <th>Employee ID</th>
            <th>Employee Name</th>
            <th>Position</th>
            <th>Reason</th>
            <th>Date of Issuing</th>
            <th>Termination Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredTerminationLetters.map((terminationLetter) => (
            <tr key={terminationLetter._id}>
              <td>{terminationLetter.phone ? terminationLetter.phone.toString().slice(-4) : "N/A"}</td>
              <td>{terminationLetter.name}</td>
              <td>{terminationLetter.position}</td>
              <td>{terminationLetter.reason}</td>
              <td>{new Date(terminationLetter.dateOfIssuing).toLocaleDateString()}</td>
              <td>{new Date(terminationLetter.terminationDate).toLocaleDateString()}</td>
              <td>
                <button onClick={() => handlePrint(terminationLetter)}>Print</button>
                <button onClick={() => handleDelete(terminationLetter._id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ViewTerminationLetters;
