import React, { useEffect, useState } from "react";
import axios from "axios";
import './EmployeeList.css'; // Ensure to create this CSS file for styling
import logoo from './logo.jpg';
import './RollOfferLetter.css';


const NEmployeeList = () => {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);

  // Function to fetch employees from the backend
  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get('https://backend.nirvinfertilizerandpesticidesprivatelimited.com/api/nirvin-employees');
        setEmployees(response.data);
      } catch (error) {
        console.error("Error fetching employee data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEmployees();
  }, []);

  // Function to handle employee deletion
  const handleDelete = async (id) => {
    const passkey = prompt("Enter the passkey to confirm deletion:");
  
    // Check if the passkey is correct (replace 'YOUR_PASSKEY' with the actual passkey)
    if (passkey !== "NFPRAJ99") {
      alert("Incorrect passkey. Deletion canceled.");
      return;
    }
  
    try {
      await axios.delete(`https://backend.nirvinfertilizerandpesticidesprivatelimited.com/api/nirvin-employees/${id}`);
      setEmployees(employees.filter(employee => employee._id !== id)); // Update the state
      alert("Employee deleted successfully.");
    } catch (error) {
      console.error("Error deleting employee:", error);
      alert("An error occurred while trying to delete the employee.");
    }
  };
  

  // Function to handle printing employee details
  const handlePrint = (employee) => {
    const printWindow = window.open("", "_blank");
    const imagePath = `https://backend.nirvinfertilizerandpesticidesprivatelimited.com/${employee.nirvinImage.replace(/\\/g, '/')}`;
   
  
    printWindow.document.write(`
      <html>
        <head>
          <title>${employee.nirvinName} - Employee Details</title>
          <style>
            /* General Reset */
            * {
              box-sizing: border-box;
              font-family: Arial, sans-serif;
              color: #333;
            }
  
            /* Overall Layout */
            body {
              padding: 20px;
              margin: 0;
              background-color: #f4f4f4;
            }
            .print-container {
              width: 80%;
              max-width: 700px;
              margin: auto;
              padding: 20px;
              background-color: #fff;
              border-radius: 10px;
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            }
  
            /* Header */
            .header {
              text-align: center;
              padding-bottom: 20px;
              border-bottom: 2px solid #4CAF50;
            }
            .header img {
              width: 100px;
              margin-bottom: 10px;
            }
            .header h1 {
              margin: 0;
              font-size: 28px;
              color: #4CAF50;
            }
            .header p {
              font-size: 14px;
              margin: 5px 0;
              color: #555;
            }
  
            /* Employee Image */
            .employee-image {
              display: block;
              margin: 20px auto;
              width: 120px;
              height: 120px;
              
              border: 3px solid #4CAF50;
            }
  
            /* Employee Details Table */
            .details-table {
              width: 100%;
              border-collapse: collapse;
              margin-top: 20px;
            }
            .details-table th, .details-table td {
              padding: 8px 12px;
              border: 1px solid #ddd;
              text-align: left;
            }
            .details-table th {
              background-color: #4CAF50;
              color: #fff;
            }
            .details-table td strong {
              color: #4CAF50;
            }
  
            /* Footer */
            .footer {
              text-align: center;
              padding-top: 20px;
              border-top: 2px solid #4CAF50;
              font-size: 12px;
              color: #888;
            }
          </style>
        </head>
        <body>
          <div class="print-container">
            <div class="header">
              <img src="${logoo}" alt="Company Logo" />
              <h1>Nirvin Fertilizer and Pesticides Pvt. Ltd</h1>
              <p><strong>Address:</strong> 11-28, Vekanuru, Avanigadda, Krishna, Andhra Pradesh, 521121</p>
              <p><strong>Email:</strong> bgudivaka@gmail.com <strong>Phone:</strong> +91 6309193999</p>
              
              <p><strong>CIN:</strong>U10509AP2023PTC113421</p>

            </div>
            <h3 style="text-align: center;">Employee Details</h3>
            
            <img src="${imagePath}" alt="${employee.nirvinName}'s image" class="employee-image" />
            <table class="details-table">
              <tr><th>Detail</th><th>Information</th></tr>
              <tr><td><strong>Name</strong></td><td>${employee.nirvinName}</td></tr>
              <tr><td><strong>Phone</strong></td><td>${employee.nirvinPhone}</td></tr>
              <tr><td><strong>Email</strong></td><td>${employee.nirvinEmail}</td></tr>
              <tr><td><strong>Position</strong></td><td>${employee.nirvinPosition}</td></tr>
              <tr><td><strong>Date of Joining</strong></td><td>${new Date(employee.nirvinDateOfJoining).toLocaleDateString()}</td></tr>
              <tr><td><strong>PAN</strong></td><td>${employee.nirvinPan}</td></tr>
              <tr><td><strong>Aadhar</strong></td><td>${employee.nirvinAadhar}</td></tr>
              <tr><td><strong>PF Number</strong></td><td>${employee.nirvinPfNumber}</td></tr>
              <tr><td><strong>ESI Number</strong></td><td>${employee.nirvinEsiNumber}</td></tr>
              <tr><td><strong>Salary</strong></td><td>${employee.nirvinSalary}</td></tr>
              <tr><td><strong>Bank Name</strong></td><td>${employee.nirvinBankName}</td></tr>
              <tr><td><strong>Account Number</strong></td><td>${employee.nirvinAccountNumber}</td></tr>
            </table>
            <div class="footer">
              <p>Generated by the Employee Management System</p>
            </div>
          </div>
        </body>
      </html>
    `);
    
    printWindow.document.close();
    printWindow.print();
  };
  
  
  

  // Loading state
  if (loading) return <p>Loading employees...</p>;

  return (
    <div className="container">
      <h2 className="list-title">Employee List</h2>
      <div className="employee-list">
        {employees.length === 0 ? (
          <p>No employees found.</p>
        ) : (
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Position</th>
                <th>Date of Joining</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {employees.map((employee) => (
                <tr key={employee._id}>
                  <td>{employee.nirvinName}</td>
                  <td>{employee.nirvinPhone}</td>
                  <td>{employee.nirvinEmail}</td>
                  <td>{employee.nirvinPosition}</td>
                  <td>{new Date(employee.nirvinDateOfJoining).toLocaleDateString()}</td>
                  <td>
                    <button onClick={() => handlePrint(employee)} className="print-button">Print</button>
                    <button onClick={() => handleDelete(employee._id)} className="delete-button">Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default NEmployeeList;
