import React, { useEffect, useState } from "react";
import axios from "axios";
import './ViewOfferLetters.css'; // Import a CSS file for styling
import logoo from './pages/logo.png'; // Company logo
import sign from './sign.png'; // Signature image

const ViewOfferLetters = () => {
  const [offerLetters, setOfferLetters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [searchMonth, setSearchMonth] = useState("");
  const [searchYear, setSearchYear] = useState("");
  const [searchId, setSearchId] = useState("");

  // Fetch offer letters on component mount
  useEffect(() => {
    const fetchOfferLetters = async () => {
      try {
        const response = await axios.get("https://backend.nirvinfertilizerandpesticidesprivatelimited.com/api/offerletters");
        setOfferLetters(response.data);
      } catch (error) {
        setError("Error fetching offer letters");
        console.error("Error fetching offer letters:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOfferLetters();
  }, []);

  const handleDelete = async (id) => {
    const passKey = "KKFRAJ88";
    const userInput = window.prompt("Please enter the pass key to confirm deletion:");

    if (userInput !== passKey) {
      alert("Invalid pass key. Deletion aborted.");
      return;
    }

    if (window.confirm("Are you sure you want to delete this offer letter?")) {
      try {
        await axios.delete(`https://backend.nirvinfertilizerandpesticidesprivatelimited.com/api/offerletters/${id}`);
        setOfferLetters((prev) => prev.filter((letter) => letter._id !== id));
      } catch (error) {
        console.error("Error deleting offer letter:", error);
      }
    }
  };

  const handlePrint = (offerLetter) => {
    const monthlySalary = parseFloat(offerLetter.salary); // Assuming salary is given as a monthly amount
    const annualSalary = (monthlySalary * 12).toFixed(2); // Calculate the annual salary
    
    const startDate = new Date(offerLetter.startDate);
    const responseDueDate = new Date(startDate);
    responseDueDate.setDate(startDate.getDate() - 2); // Set due date to two days before start date
    
    const printWindow = window.open('', '', 'width=800,height=600');
    printWindow.document.write(`
      <html>
        <head>
          <title>Offer Letter</title>
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">
          <style>
            @media print {
              body { 
                font-family: 'Arial', sans-serif; 
                margin: 0; 
                padding: 20px; 
                background-color: #f7f7f7; 
                color: #333; 
              }
              .offer-letter { 
                max-width: 700px; 
                margin: auto; 
                padding: 30px; 
                background: white; 
                border: none; 
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
                border-radius: 5px; 
                font-size: 16px; 
              }
              .header { 
                text-align: center; 
                margin-bottom: 20px; 
              }
              .header img { 
                width: 80px; 
                margin-bottom: 10px; 
              }
              .header h2 { 
                font-size: 24px; 
                margin: 0; 
              }
              .header p { 
                font-size: 14px; 
                margin: 4px 0; 
              }
              .greeting { 
                margin: 20px 0; 
                font-size: 18px; 
                font-weight: bold; 
              }
              .content { 
                margin: 20px 0; 
                font-size: 16px; 
              }
              .footer { 
                display: grid; 
                grid-template-columns: 1fr 1fr; 
                gap: 20px; 
                margin-top: 30px; 
                font-size: 14px; 
                text-align: center; 
                align-items: center; 
              }
              .signature-box img { 
                height: 60px; 
                width: 100px; 
              }
              .signature-box p { 
                margin-top: 10px; 
                font-weight: bold; 
              }
                .signature-b p { 
                margin-top: 66px; 
                font-weight: bold; 
              }
            }
          </style>
        </head>
        <body>
          <div class="offer-letter">
            <div class="header">
              <img src=${logoo} alt="Company Logo" />
              <h2>Klin Kaara Foundation</h2>
              <p>11, Vekanuru, Avanigadda, Krishna, Andhra Pradesh, 521121, India</p>
              <p>Phone: +91-6309193999 | Email: info@klinkaarafoundation.org</p>
              <p>CIN: U85500AP2024NPL114246</p>
              <h3>Offer Letter</h3>
            </div>
    
            <div class="greeting">
              Dear ${offerLetter.name},
            </div>
            
            <div class="content">
              <p>We are pleased to offer you the position of <strong>${offerLetter.position}</strong> at Klin Kaara Foundation.</p>
              
              <p>Your starting salary will be <strong>${offerLetter.salary} per month</strong>, which equates to an annual salary of <strong>${annualSalary} per annum</strong>. Your expected start date is <strong>${startDate.toLocaleDateString()}</strong>.</p>
              
              <p>Your regular working hours will be from <strong>10:00 AM</strong> to <strong>06:00 PM</strong>, Monday through Friday.</p>
              
              <p>This offer is subject to a probation period of <strong>45 days</strong>, during which your performance will be reviewed.</p>
              
              <p>In addition to your salary, you will be entitled to the following benefits:</p>
              <ul>
                <li>Health insurance coverage</li>
                <li>Paid time off and holidays</li>
                <li>Professional development opportunities</li>
                <li>Participation in employee wellness programs</li>
              </ul>
    
              <p>We believe your skills and experiences will be a valuable addition to our team. Please confirm your acceptance of this offer by signing and returning this letter by <strong>${responseDueDate.toLocaleDateString()}</strong>.</p>
            </div>
    
            <div class="footer">
              <div class="signature-b">
                <p><strong>Employee Signature</strong></p>
              </div>
              <div class="signature-box">
                <img src=${sign} alt="Director Signature" />
                <p><strong>Director Signature</strong></p>
              </div>
            </div>
          </div>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };
  
  
  
  

  // Filter offer letters based on the search month, year, and ID
  const filteredOfferLetters = offerLetters.filter(letter => {
    const letterDate = new Date(letter.dateOfIssuing);

    const monthMatch = letterDate.toLocaleString('default', { month: 'long' }).toLowerCase().includes(searchMonth.toLowerCase());
    const yearMatch = letterDate.getFullYear().toString().includes(searchYear);
    const idMatch = letter.employeeId && letter.employeeId.includes(searchId); // Assuming employeeId exists

    return monthMatch && yearMatch && idMatch;
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container">
      <h2 className="offer-letters-title">Offer Letters</h2>

      <div className="search-container">
        <input
          type="text"
          placeholder="Search by Employee ID"
          value={searchId}
          onChange={(e) => setSearchId(e.target.value)}
        />
        <input
          type="text"
          placeholder="Search by month name"
          value={searchMonth}
          onChange={(e) => setSearchMonth(e.target.value)}
        />
        <input
          type="text"
          placeholder="Search by year"
          value={searchYear}
          onChange={(e) => setSearchYear(e.target.value)}
        />
      </div>
      <table className="offer-letter-table">
        <thead>
          <tr>
            <th>Employee ID</th>
            <th>Employee Name</th>
            <th>Position</th>
            <th>Date of Issuing</th>
            <th>Salary</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredOfferLetters.map((offerLetter) => (
            <tr key={offerLetter._id}>
              <td>{offerLetter.phone ? offerLetter.phone.toString().slice(-4) : "N/A"}</td>
              <td>{offerLetter.name}</td>
              <td>{offerLetter.position}</td>
              <td>{new Date(offerLetter.dateOfIssuing).toLocaleDateString()}</td>
              <td>{offerLetter.salary}</td>
              <td>
                <button onClick={() => handlePrint(offerLetter)}>Print</button>
                <button onClick={() => handleDelete(offerLetter._id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ViewOfferLetters;
